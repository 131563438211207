.second-section {
  background-color: #0a192f;
  color: white;
  padding: 50px 0px 150px 0px;
}
@media (max-width: 999px) {
  .second-section {
    padding: 20px;
  }
}
