@font-face {
  font-family: "Eurostile";
  /* src: url("../src/components/shared/Eurostile.ttf") format("truetype"); */
}
@font-face {
  font-family: "EurostileBold";
  src: url("../src/components/shared/EurostileBold.ttf") format("truetype");
  font-weight: bold;
}

code {
  font-family: "Eurostile";
}
h1 {
  font-family: "EurostileBold", sans-serif;
  font-size: 5rem !important;
  /* color: #141c3a; */
  margin: 20px 0px;
}
h2 {
  font-family: "EurostileBold", sans-serif;
  font-size: 3.8rem !important;
  /* color: #141c3a; */
  margin: 20px 0px;
}
h3 {
  font-family: "EurostileBold", sans-serif;
  font-size: 3.2rem !important;
  margin: 20px 0px;
}
h4 {
  font-family: "EurostileBold", sans-serif;
  font-size: 2.6rem !important;
  margin: 20px 0px;
}
h5 {
  font-family: "EurostileBold", sans-serif;
  font-size: 2.2rem !important;
  margin: 20px 0px;
}
h6 {
  font-family: "EurostileBold", sans-serif;
  font-size: 1.6rem !important;
  margin: 20px 0px;
}
p {
  font-size: 16px;
}
.nav-link {
  font-size: 1.5rem;
}
@media (max-width: 999px) {
  h1 {
    font-size: 3rem !important;
  }
  h2 {
    font-family: "EurostileBold", sans-serif;
    font-size: 2.4rem !important;
    /* color: #141c3a; */
  }
  h3 {
    font-family: "EurostileBold", sans-serif;
    font-size: 2.2rem !important;
  }
  h4 {
    font-family: "EurostileBold", sans-serif;
    font-size: 2rem !important;
  }
  h5 {
    font-family: "EurostileBold", sans-serif;
    font-size: 1.6rem !important;
  }
  h6 {
    font-family: "EurostileBold", sans-serif;
    font-size: 1.4rem !important;
    margin: 0;
  }
  p {
    font-size: 18px;
  }
  nav-link {
    font-size: 1.4rem;
  }
}
ul li {
 margin: 10px 0px;
}
p.location {
  font-weight: bold;
}
ul {
  margin: 0;
  padding: 0;
}
.button-default {
  padding: 1.25em 2em;
  border-width: 2px;
  background-color: transparent;
  border-color: #5be9b9;

  font-size: 1.25rem;
  font-weight: 400 !important;
  cursor: pointer;
  border-radius: 12px;
}

h1
/* h2,
h3,
h4,
h5,
h6,
p,
a,
li  */ {
  color: #141c3a;
}
.dark-mode h1
/* .dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode p,
.dark-mode a,
.dark-mode li  */ {
  color: white;
}
