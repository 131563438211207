.experience-desc {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  text-align: left;
  padding-left: 20px;
}

.experience-desc.show {
  max-height: 1000px;
  transition: max-height 0.5s ease-in;
}

.experience-brief h6 {
  margin: 0px;
}

.experience-brief {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #112240;
  color: white;
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 12px;
}

.experience-brief h6 {
  margin: 0;
}

.experience-desc {
  /* padding: 20px; */
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
  padding-top: 0;
  margin-bottom: 20px;
}

.single-experience.active .experience-desc {
  max-height: 1000px;
}
