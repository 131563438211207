.footer {
  color: white;
  background-color: #0a192f;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 250px;
}
.socials img {
  width: 40px;
  margin: 0px 10px;
  background: white;
  padding: 3px;
  border-radius: 200px;
}
.start-project {
  margin-top: -6rem;
  background-color: #112240;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 0 0 1px #141c3a;
  padding: 1.5rem 1.25rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.start-project h3 {
  font-size: 2rem;
}
.sp-col {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}
.sp-col button {
  padding: 1.25em 2em;
  border-width: 2px;
  background-color: transparent;
  border-color: #5be9b9;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400 !important;
  cursor: pointer;
  border-radius: 12px;
}
.footer-btm {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

form#contact-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  margin: 20px auto;
}
input#fullname,
input#email {
  padding: 10px;
  border-radius: 6px;
  border: none;
  margin: 10px 0px;
}
textarea#message {
  height: auto;
  padding: 10px;
  border-radius: 6px;
  margin: 10px 0px;
  border: none;
}

input#submitForm {
  padding: 15px;
  border-width: 2px;
  background-color: transparent;
  border-color: #5be9b9;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 400 !important;
  cursor: pointer;
  border-radius: 12px;
  width: 40%;
  margin: 10px auto;
}

@media (max-width: 999px) {
  .footer {
    margin-top: 100px;
    position: relative;
  }
  .start-project {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .start-project h3 {
    margin: 0;
  }
  .sp-col {
    padding: 0;
  }
  .footer-btm {
    padding: 0px 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 30px 0px;
  }
  form#contact-form {
    width: 100%;
  }
}
