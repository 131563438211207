.third-section {
  /* background-color: #6e07f3; */
  display: flex;
}
.card {
  box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
  border-radius: 12px;
  margin: 1rem;
  padding: 1.2rem;
  background: white;
  margin-top: -100px;
  width: 33.3%;
  color: #112240;
}
.dark-mode .card{
  color: white;
  background: #112240;
}
.skillset li {
  margin: 5px 0px;
}
.skillset {
  display: flex;
  flex-wrap: wrap;
}

.skill-div {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  border: 1px solid #e4e8f1;
  border-radius: 12px;
  padding: 0.625rem 1rem;
  text-align: center;
  justify-content: center;
  /* width: 90px; */

  margin: 5px;
}

.skill-div p {
  margin: 0;
  font-size: 14px;
  color: #112240;
  font-weight: 700;
}
.dark-mode .skill-div p{
  color: white;
}

.skill-img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}
.project-head {
  display: flex;
  justify-content: space-between;
}
.folder {
  color: #64ffda;
  width: 40px;
  height: 40px;
}
.project-links {
  width: 40px;
  color: #64ffda;
  height: 40px;
}
.project-links svg {
  color: #a8b2d1;
}
.project-links svg:hover {
  color: #64ffda;
}

@media (max-width: 999px) {
  .third-section {
    flex-direction: column;
  }
  .card {
    margin: 10px 0px !important;
    width: auto;
  }
}
