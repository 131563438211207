.fourthsection {
  padding: 50px 20px;
}
.project-card {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 50px;
  margin: 20px 0px;
  border-radius: 6px;
  color: #112240;
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
}
.dark-mode .project-card {
 
  background: #0a192f ;
color:white;
}
.project-card:nth-of-type(even) {
  flex-direction: row-reverse;
}

.project-image {
  width: 50%;
  z-index: 0;
}
#emailme{
 
    text-decoration: none;
   
    font-weight: 700;
    margin-left: 5px;
}
.dark-text{
  color: white;
}
.light-text{
  color: black;
}
.project-image img {
  /* opacity: 0.3; */
  width: 120%;
  height: 100%;
  border-radius: 4px;
  max-width: 550px;
  max-height: 350px;
  mix-blend-mode: multiply;
  filter: grayscale(60%) contrast(0.8) brightness(90%);
  object-fit: cover;
}
.project-image:hover img {
  /* opacity: 0.3; */
  width: 120%;
  height: 100%;
  border-radius: 4px;
  max-width: 550px;
  max-height: 350px;
  mix-blend-mode: none;
  filter: none;
}
.project-link {
  text-decoration: none;
  color: #112240;
}
.dark-mode .project-link{
  color: white;
}
svg.feather.feather-external-link {
  width: 22px;
  height: 22px;
  margin-top: -4px;
  cursor: pointer;
}
.project-card:nth-child(even) .project-details {
  align-items: start;
  text-align: left;
}
.project-card:nth-child(odd) .project-details {
  align-items: end;
  text-align: right;
}
.project-card:nth-child(even) .project-image img {
  margin-left: -20%;
}
.description {
  background-color: white;
  z-index: 999999;
  padding: 25px;

  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  border-radius: 4px;
}
.dark-mode .description{
  background-color: #112240;

}
.project-details {
  display: flex;
  flex-direction: column;
  width: 50%;

  justify-content: space-evenly;
}
.project-tech-list {
  display: flex;
}
.project-tech-list li {
  margin: 0px 20px 5px 0px;
  color: #a8b2d1;
  font-size: 14px;
}
.project-title {
  font-size: 28px !important;
}
@media (max-width: 999px) {
  .project-card {
    flex-direction: column;
    padding: 20px;
  }
  .project-image {
    width: 100%;
    max-width: 100%;
  }
  .project-image img {
    width: 100%;
  }
  .project-details {
    width: 100%;
  }
  .project-card:nth-of-type(even) {
    flex-direction: column;
  }
  .projects-holder.container {
    padding: 0;
  }
  .project-card:nth-child(even) .project-details {
    align-items: center;
    text-align: left;
  }
  .project-card:nth-child(odd) .project-details {
    align-items: center;
    text-align: left;
  }
  .project-card:nth-child(even) .project-image img {
    margin-left: -0%;
  }
  .project-tech-list {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .project-link {
    width: 100%;
    text-align: right;
    margin-top: -20px;
  }
  .project-image img {
    mix-blend-mode: none;
    filter: none;
    object-fit: cover;
  }
}
