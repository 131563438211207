.first-section {
  padding-top: 100px;
  margin: auto;
  color: var(--text-color);
  background-color: var(--background-color);
}

.first-section img {
  width: 500px;
  height: 100%;
  padding-top: 100px;
}
@media (max-width: 999px) {
  .first-section {
    padding-top: 30px;
  }
  .first-section img {
    width: 100%;
    height: 100%;
  }
}
