.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 50px;
}
.nav-link:hover {
  cursor: pointer;
 }
 
.navbar-toggler {
  border: none;
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.myname {
  margin-left: 20px;
}

.navbar-toggler-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #333;
}

.navbar-nav {
  margin-top: 1rem;
  display: flex;
}

.nav-item {
  margin-right: 5rem;
  list-style: none;
  
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
}


.hamburger-react {
  display: none;
}
.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  width: 40px;
  height: 15px;
  background-color: #111;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
}

.ball {
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked + .label .ball {
  transform: translateX(24px);
}

.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}
@media (max-width: 992px) {
  .label {
    margin: 0 auto;
  }
  .hamburger-react {
    display: block;
  }
  .myname {
    margin: auto;
  }
  .navbar-nav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: var(--background-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .navbar-brand {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-nav.show {
    padding: 0;
    display: block !important;
    position: absolute;
    top: 100px;
    bottom: 0;
    height: 100%;
    overflow: hidden;
  }
  .navbar-nav.show .navbar-nav {
    display: block !important;
  }

  .nav-item {
    display: block;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--text-color);
    text-decoration: none;
  }

  .nav-link:hover {
   cursor: pointer;
    background-color: #f7f7f7;
  }

  .navbar-toggler {
    display: block;
  }
}
