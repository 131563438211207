.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  margin: 0;
  font-family: "Eurostile", sans-serif;

  color: var(--text-color);
  background-color: var(--background-color);
}
:root {
  --text-color: #020c1b; /* default text color */
  --background-color: #fff; /* default background color */
}

.dark-mode {
  --text-color: #eee; /* text color for dark mode */
  --background-color: #020c1b; /* background color for dark mode */
}
.App.dark-mode {
  background-color: var(--background-color);
  color: var(--text-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  max-width: 1100px;
  margin: auto;
}
@media (max-width: 999px) {
  .container {
    max-width: 100%;
    /* width: 100%; */
    padding: 0px 20px;
  }
}
